import React from "react"
import { CSSTransition } from "react-transition-group"
import styled from "styled-components"

const Container = styled.svg`
  cursor: pointer;
  &.enter {
    transform: scale(2.5);
    opacity: 0;
  }
  &.enter-active {
    transform: scale(1);
    opacity: 1;
    transition: all 150ms ease-out;
  }
  &.exit {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
  &.exit-active {
    transform: scale(2.5);
    opacity: 0;
    transition: all 250ms ease-in;
  }
  &.exit-done {
    visibility: hidden;
  }
`

export default function Play({ active, color = "#fff" }) {
  return (
    <CSSTransition in={active} timeout={250}>
      <Container
        width="84"
        height="84"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
          fill={color}
        />
        <path d="M16 12L10 16.3301V7.66987L16 12Z" fill={color} />
      </Container>
    </CSSTransition>
  )
}
