export const getVideoIdFromVimeoUrl = (embedUrl) => {
  const vimeoRegEx = /(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/
  return embedUrl.match(vimeoRegEx)[5]
}
export const getVideoIdFromYoutubeUrl = (embedUrl) => {
  const youtubeRegEx = /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
  return embedUrl.match(youtubeRegEx)[1]
}

export const getVideoIdFromEmbedUrl = (provider, embedUrl) => {
  if (!provider) return false

  switch (provider.toLowerCase()) {
    case "youtube":
      return getVideoIdFromYoutubeUrl(embedUrl)

    case "vimeo":
      return getVideoIdFromVimeoUrl(embedUrl)
  }
}

export default getVideoIdFromEmbedUrl

